/************ Skandinavisk colors ***********/
/******* Aspect ratios *****/
/****** Transition *******/
.text-container {
  max-width: var(--max-width);
  margin: 0 auto;
}
.text-container ul,
.text-container li,
.text-container ol {
  list-style: unset;
  margin: unset;
  padding: unset;
}
.text-container.text-container--v-small {
  padding: 16px 0;
}
.text-container.text-container--v-medium {
  padding: 30px 0;
}
.text-container.text-container--v-big {
  padding: 40px 0;
}
.text-container.text-container--h-small {
  padding-left: 16px;
  padding-right: 16px;
}
.text-container.text-container--h-medium {
  padding-left: 30px;
  padding-right: 30px;
}
.text-container.text-container--h-big {
  padding-left: 60px;
  padding-right: 60px;
}
@media only screen and (max-width: 1199px) {
  .text-container.text-container--v-small, .text-container.text-container--v-medium, .text-container.text-container--v-big {
    padding: 16px 0;
  }
  .text-container.text-container--h-small, .text-container.text-container--h-medium, .text-container.text-container--h-big {
    padding-left: 16px;
    padding-right: 16px;
  }
}

/* stylelint-disable no-descending-specificity */
p {
  font-size: 14px;
  line-height: 24px;
}
@media only screen and (max-width: 1199px) {
  p {
    font-size: 14px;
    line-height: 22px;
  }
}

.text-title p,
.text-title h1,
.text-title h2,
.text-title h3,
.text-title h4,
.text-title ul,
.text-title li,
.text-title ol {
  color: var(--title-color) !important;
  text-align: var(--title-align) !important;
}
.text-content p,
.text-content h1,
.text-content h2,
.text-content h3,
.text-content h4,
.text-content ul,
.text-content li,
.text-content ol {
  color: var(--text-color) !important;
  text-align: var(--text-align) !important;
}
.text-title--font-small p,
.text-title--font-small h1,
.text-title--font-small h2,
.text-title--font-small h3,
.text-title--font-small h4,
.text-title--font-small ul,
.text-title--font-small li,
.text-title--font-small ol {
  font-size: 18px;
}
@media only screen and (max-width: 1199px) {
  .text-title--font-small p,
.text-title--font-small h1,
.text-title--font-small h2,
.text-title--font-small h3,
.text-title--font-small h4,
.text-title--font-small ul,
.text-title--font-small li,
.text-title--font-small ol {
    font-size: 16px;
  }
}
.text-title--font-medium p,
.text-title--font-medium h1,
.text-title--font-medium h2,
.text-title--font-medium h3,
.text-title--font-medium h4,
.text-title--font-medium ul,
.text-title--font-medium li,
.text-title--font-medium ol {
  font-size: 20px;
}
@media only screen and (max-width: 1199px) {
  .text-title--font-medium p,
.text-title--font-medium h1,
.text-title--font-medium h2,
.text-title--font-medium h3,
.text-title--font-medium h4,
.text-title--font-medium ul,
.text-title--font-medium li,
.text-title--font-medium ol {
    font-size: 18px;
  }
}
.text-title--font-big p,
.text-title--font-big h1,
.text-title--font-big h2,
.text-title--font-big h3,
.text-title--font-big h4,
.text-title--font-big ul,
.text-title--font-big li,
.text-title--font-big ol {
  font-size: 36px;
}
@media only screen and (max-width: 1199px) {
  .text-title--font-big p,
.text-title--font-big h1,
.text-title--font-big h2,
.text-title--font-big h3,
.text-title--font-big h4,
.text-title--font-big ul,
.text-title--font-big li,
.text-title--font-big ol {
    font-size: 22px;
  }
}
.text-title--line-small p,
.text-title--line-small h1,
.text-title--line-small h2,
.text-title--line-small h3,
.text-title--line-small h4,
.text-title--line-small ul,
.text-title--line-small li,
.text-title--line-small ol {
  line-height: 1;
}
.text-title--line-medium p,
.text-title--line-medium h1,
.text-title--line-medium h2,
.text-title--line-medium h3,
.text-title--line-medium h4,
.text-title--line-medium ul,
.text-title--line-medium li,
.text-title--line-medium ol {
  line-height: 1.5;
}
.text-title--line-big p,
.text-title--line-big h1,
.text-title--line-big h2,
.text-title--line-big h3,
.text-title--line-big h4,
.text-title--line-big ul,
.text-title--line-big li,
.text-title--line-big ol {
  line-height: 2;
}
.text-content--font-small p,
.text-content--font-small h1,
.text-content--font-small h2,
.text-content--font-small h3,
.text-content--font-small h4,
.text-content--font-small ul,
.text-content--font-small li,
.text-content--font-small ol {
  font-size: 16px;
}
@media only screen and (max-width: 1199px) {
  .text-content--font-small p,
.text-content--font-small h1,
.text-content--font-small h2,
.text-content--font-small h3,
.text-content--font-small h4,
.text-content--font-small ul,
.text-content--font-small li,
.text-content--font-small ol {
    font-size: 14px;
  }
}
.text-content--font-medium p,
.text-content--font-medium h1,
.text-content--font-medium h2,
.text-content--font-medium h3,
.text-content--font-medium h4,
.text-content--font-medium ul,
.text-content--font-medium li,
.text-content--font-medium ol {
  font-size: 18px;
}
@media only screen and (max-width: 1199px) {
  .text-content--font-medium p,
.text-content--font-medium h1,
.text-content--font-medium h2,
.text-content--font-medium h3,
.text-content--font-medium h4,
.text-content--font-medium ul,
.text-content--font-medium li,
.text-content--font-medium ol {
    font-size: 16px;
  }
}
.text-content--font-big p,
.text-content--font-big h1,
.text-content--font-big h2,
.text-content--font-big h3,
.text-content--font-big h4,
.text-content--font-big ul,
.text-content--font-big li,
.text-content--font-big ol {
  font-size: 20px;
}
@media only screen and (max-width: 1199px) {
  .text-content--font-big p,
.text-content--font-big h1,
.text-content--font-big h2,
.text-content--font-big h3,
.text-content--font-big h4,
.text-content--font-big ul,
.text-content--font-big li,
.text-content--font-big ol {
    font-size: 18px;
  }
}
.text-content--line-small p,
.text-content--line-small h1,
.text-content--line-small h2,
.text-content--line-small h3,
.text-content--line-small h4,
.text-content--line-small ul,
.text-content--line-small li,
.text-content--line-small ol {
  line-height: 1;
}
.text-content--line-medium p,
.text-content--line-medium h1,
.text-content--line-medium h2,
.text-content--line-medium h3,
.text-content--line-medium h4,
.text-content--line-medium ul,
.text-content--line-medium li,
.text-content--line-medium ol {
  line-height: 1.5;
}
.text-content--line-big p,
.text-content--line-big h1,
.text-content--line-big h2,
.text-content--line-big h3,
.text-content--line-big h4,
.text-content--line-big ul,
.text-content--line-big li,
.text-content--line-big ol {
  line-height: 2;
}