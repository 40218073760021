// Queries:
$dt-max: 'only screen and (max-width: 1600px)'; // dt-max
$dt-med: 'only screen and (max-width: 1366px)'; // dt-med
$dt-min: 'only screen and (max-width: 1280px)'; // dt-min

$tablet-max: 'only screen and (max-width: 1199px)'; // tablet and smaller
$desktop: 'only screen and (min-width: 1024px)'; // anything above desktop
$medium-desktop: 'only screen and (min-width: 1281px)';
$custom-laptop: 'only screen and (min-width: 1200px) and (max-width: 1400px)';

$not-mobile-small: 'only screen and (min-width: 560px)'; // anything above mobile small max
$not-mobile: 'only screen and (min-width: 768px)'; // anything above mobile
$not-mobile-tablet: 'only screen and (min-width: 1200px)'; // anything above tablet (overruling in other direction)
$not-desktop: 'only screen and (max-width: 1023px)'; //for small laptops

$dt-tablet-ls: 'only screen and (min-width: 901px)'; // dt-tablet-ls
$tablet-only: 'only screen and (min-width: 768px) and (max-width: 1199px)'; // tablet-only
$tablet-ls: 'only screen and (min-width: 901px) and (max-width: 1199px)'; // tablet-ls
$tablet-pt: 'only screen and (min-width: 768px) and (max-width: 900px)'; // tablet-pt
$large-mobile: 'only screen and (max-width: 992px)'; // large device
$mobile-tablet-pt: 'only screen and (max-width: 900px)'; // mobile-tablet-pt
$mobile-max: 'only screen and (max-width: 767px)'; // below mobile
$mobile-small-max: 'only screen and (max-width: 560px)'; // mobile small max
$mobile-pt: 'only screen and (max-width: 415px)'; // mobile portrait (biggest iphone)
$mobile-medium: 'only screen and (max-width: 350px)'; // mobile portrait (medium iphone)
$mobile-small: 'only screen and (max-width: 320px)'; // mobile portrait (smallest iphone)
$mobile-landscape: 'only screen and (max-width: 767px) and (orientation: landscape)';

$print: 'print';
$ie11: 'screen and (-ms-high-contrast: active), (-ms-high-contrast: none)';
$hover: '(hover: hover)';

// * Media Queries */

@mixin mq($values: '') {
    @if ($values != '') {
        @each $val in $values {
            @media #{$val} {
                @content;
            }
        }
    }
}
