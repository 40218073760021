// ***************** VARIABLES ***************** //
// Description:
// General global variables used in the project
// --------------------------------------------------

//////////////////////////////////////////////////////Level 1 Variables///////////////////////////////////////////////////////////////

// ==================================================
// Font Family
// ==================================================
// font-weight: 400; = normal
// font-weight: 700; = demi bold
$font-primary: "Skandinavisk", sans-serif; //normal
$font-primary-bold: "SkandinaviskBold", sans-serif; // demi bold
$font-secondary: Arial, serif; // not realy used so far
$iconfont: "iconfont";

// ==================================================
// Global Padding
// ==================================================
$padding-xl: 60px;
$padding-l: 40px;
$padding-m: 30px;
$padding-s: 16px;
$padding-xs: 10px;

// ==================================================
// Width
// ==================================================
$max-width: 1880px;
// $max-width: 1560px; // (1440 + 2x60)
$max-width-container: 1440px;
$max-width-centercol: 1240px;
$medium-col: 1088px;

// ==================================================
// Input / select height
// ==================================================
$height-input: 52px;

// ==================================================
// Colors :
// ==================================================

$black: #000;
$black2: #110606;
$white: #fff;

$black-op1: rgba(0, 0, 0, 0.4);
$black-op2: rgba(0, 0, 0, 0.8);
$black-op3: rgba(0, 0, 0, 0.9);

$red: #f27474;
$red2: #ea7d7d;
$pink: #ffc4be;
$orange: #f8bb86;

$blue1: #0078d7;
$blue2: #3fc3ee;
$blue3: #c9dae1;

$green: #2bc137;
$green1: #284926;
$green2: #a5dc86;

/************ Skandinavisk colors ***********/
$grey1: #f4f4f4;
$grey2: #e2e2e2;
$grey3: #d4d4d4;
$grey4: #606060;
$grey5: #646464;
$grey6: #a8a8a8;

$brown1: #ac937f;

$error-red: #f00000;
$red-dark: #901313;


/******* Aspect ratios *****/
$ratio-1-1: 100%;
$ratio-3-2: 66.66%;
$ratio-16-9: 56.25%;

/****** Transition *******/
$transition: transform 0.3s ease-in;
