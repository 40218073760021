@import '../../pages/page-include';

.text-container {
    max-width: var(--max-width);
    margin: 0 auto;

    ul,
    li,
    ol {
        list-style: unset;
        margin: unset;
        padding: unset;
    }

    &.text-container--v-small {
        padding: $padding-s 0;
    }

    &.text-container--v-medium {
        padding: $padding-m 0;
    }

    &.text-container--v-big {
        padding: $padding-l 0;
    }

    &.text-container--h-small {
        padding-left: $padding-s;
        padding-right: $padding-s;
    }

    &.text-container--h-medium {
        padding-left: $padding-m;
        padding-right: $padding-m;
    }

    &.text-container--h-big {
        padding-left: $padding-xl;
        padding-right: $padding-xl;
    }

    @include mq($tablet-max) {
        &.text-container--v-small,
        &.text-container--v-medium,
        &.text-container--v-big {
            padding: $padding-s 0;
        }

        &.text-container--h-small,
        &.text-container--h-medium,
        &.text-container--h-big {
            padding-left: $padding-s;
            padding-right: $padding-s;
        }
    }
}

/* stylelint-disable no-descending-specificity */
p {
    font-size: 14px;
    line-height: 24px;

    @include mq($tablet-max) {
        font-size: 14px;
        line-height: 22px;
    }
}

p,
h1,
h2,
h3,
h4,
ul,
li,
ol {
    .text-title & {
        color: var(--title-color) !important;
        text-align: var(--title-align) !important;
    }

    .text-content & {
        color: var(--text-color) !important;
        text-align: var(--text-align) !important;
    }

    .text-title--font-small & {
        font-size: 18px;

        @include mq($tablet-max) {
            font-size: 16px;
        }
    }

    .text-title--font-medium & {
        font-size: 20px;

        @include mq($tablet-max) {
            font-size: 18px;
        }
    }

    .text-title--font-big & {
        font-size: 36px;

        @include mq($tablet-max) {
            font-size: 22px;
        }
    }

    .text-title--line-small & {
        line-height: 1;
    }

    .text-title--line-medium & {
        line-height: 1.5;
    }

    .text-title--line-big & {
        line-height: 2;
    }

    .text-content--font-small & {
        font-size: 16px;

        @include mq($tablet-max) {
            font-size: 14px;
        }
    }

    .text-content--font-medium & {
        font-size: 18px;

        @include mq($tablet-max) {
            font-size: 16px;
        }
    }

    .text-content--font-big & {
        font-size: 20px;

        @include mq($tablet-max) {
            font-size: 18px;
        }
    }

    .text-content--line-small & {
        line-height: 1;
    }

    .text-content--line-medium & {
        line-height: 1.5;
    }

    .text-content--line-big & {
        line-height: 2;
    }
}
